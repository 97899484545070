



















































































import { defineComponent, Ref, computed, ref } from '@vue/composition-api';
import { useQuery, useResult } from '@vue/apollo-composable';
import eventsQuery from '../queries/events.gql';
import pressQuery from '../queries/press.gql';
import podcastQuery from '../queries/podcast.gql';

export default defineComponent({
  props: {
    section: {
      type: String,
      required: true,
    },
    offset: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    loadMoreLabel: {
      type: String,
      required: true,
    },
    teaserLinkLabel: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    let query: any;
    const classes = ref('');
    const limit = props.offset * 2;
    const enabled = ref(false);

    switch (props.section) {
      case 'events':
        query = eventsQuery;
        classes.value = 'w-1/2 md:w-1/3';
        break;
      case 'press':
        query = pressQuery;
        classes.value = '';
        break;
      case 'podcast':
        query = podcastQuery;
        classes.value = '';
        break;

      default:
        break;
    }

    const { result, fetchMore, loading, error } = useQuery(
      query,
      () => ({
        limit,
        offset: props.offset,
        site: document.documentElement.lang,
      }),
      () => ({
        fetchPolicy: 'cache-and-network',
        enabled: enabled.value,
        errorPolicy: 'all',
      }),
    );
    const entries: Readonly<Ref<any>> = useResult(
      result,
      null,
      data => data.entries,
    );

    const loadMore = () => {
      enabled.value = true;
      const offset = props.offset + (entries.value ? entries.value.length : 0);

      fetchMore({
        variables: {
          offset,
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          // No new entries posts
          if (!fetchMoreResult) return previousResult;

          // Concat previous entries with new entries posts
          return {
            ...previousResult,
            entries: [...previousResult.entries, ...fetchMoreResult.entries],
          };
        },
      });
    };

    const showLoadMore = computed((): boolean => {
      if (entries.value) {
        return (
          entries.value.length > 0 &&
          entries.value.length < props.total - props.offset
        );
      }
      return props.total > props.offset;
    });

    return { showLoadMore, loadMore, entries, classes, loading, error };
  },
});
