










































import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  computed,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
  },

  setup() {
    const audioElement: Ref<HTMLAudioElement | null> = ref(null);
    const playing = ref(false);
    const loaded = ref(false);
    const dirty = ref(false);
    const durationSeconds = ref(0);
    const currentSeconds = ref(0);

    const load = () => {
      if (audioElement.value && audioElement.value.readyState >= 2) {
        loaded.value = true;
        durationSeconds.value = audioElement.value.duration;
        playing.value = !audioElement.value.paused;
      } else {
        throw new Error('Failed to load sound file.');
      }
    };

    const update = () => {
      if (audioElement.value) {
        currentSeconds.value = audioElement.value.currentTime;
      }
    };

    onMounted(() => {
      if (audioElement.value) {
        audioElement.value.addEventListener('timeupdate', update);
        audioElement.value.addEventListener('loadeddata', load);
        audioElement.value.addEventListener('pause', () => {
          playing.value = false;
        });
        audioElement.value.addEventListener('play', () => {
          playing.value = true;
        });
      }
    });

    const convertTimeHHMMSS = (val: number) => {
      const duration = new Date(val * 1000);

      return `${duration.toISOString().substr(11, 8)}`;
    };

    const currentTime = computed(() => {
      return convertTimeHHMMSS(currentSeconds.value);
    });

    const durationTime = computed(() => {
      return convertTimeHHMMSS(durationSeconds.value);
    });

    const seek = () => {
      if (audioElement.value) {
        audioElement.value.currentTime = currentSeconds.value;
      }
    };

    watch(playing, value => {
      if (audioElement.value) {
        if (value) {
          audioElement.value.play();
          dirty.value = true;
        } else {
          audioElement.value.pause();
        }
      }
    });

    return {
      audioElement,
      currentTime,
      playing,
      durationSeconds,
      currentSeconds,
      durationTime,
      seek,
      dirty,
    };
  },
});
