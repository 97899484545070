










import {
  defineComponent,
  SetupContext,
  ref,
  Ref,
  watch,
  onMounted,
  inject,
} from '@vue/composition-api';

export default defineComponent({
  setup(props, context: SetupContext) {
    const el: Ref<HTMLElement | null> = ref(null);
    const introEnabled = ref(true);
    const navigationVisible = ref(false);

    context.root.$on('introEnabled', (value: boolean) => {
      introEnabled.value = value;
    });

    const toggleNavigation = () => {
      navigationVisible.value = !navigationVisible.value;
    };

    watch(navigationVisible, visible => {
      const main = document.getElementById('main');
      if (main) {
        if (visible) {
          main.setAttribute('inert', 'inert');
          document.documentElement.style.overflow = 'hidden';
        } else {
          main.removeAttribute('inert');
          document.documentElement.style.overflow = '';
        }
      }
    });

    return {
      el,
      introEnabled,
      navigationVisible,
      toggleNavigation,
    };
  },
});
