import { DirectiveBinding } from 'vue/types/options';
import { VNode } from 'vue/types/umd';
import { expand, collapse } from '../utils';

const toggle = {
  bind(el: Element, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode) {
    let expanded = false;

    vnode.context?.$nextTick(() => {
      const target = document.getElementById(binding.value.id);

      if (target) {
        target.style.overflowY = 'hidden';
        target.style.maxHeight = '0';
        target.style.transition = 'max-height 0.2s ease-out';
        target.setAttribute('inert', 'inert');
      }

      el.addEventListener('click', () => {
        if (target) {
          if (!expanded) {
            expand(target);
            el.classList.add('expanded');
            el.setAttribute('aria-pressed', 'true');
          } else {
            collapse(target);
            el.classList.remove('expanded');
            el.setAttribute('aria-pressed', 'false');
          }
        }
        expanded = !expanded;
      });

      if (target && binding.value.expanded) {
        expand(target);
        el.classList.add('expanded');
        el.setAttribute('aria-pressed', 'true');
        expanded = true;
      }
    });
  },
};

export default toggle;
