













import { defineComponent, ref, Ref, SetupContext } from '@vue/composition-api';
import { expand } from '../utils';

export default defineComponent({
  props: {
    readMoreLabel: {
      type: String,
      default: 'Read more',
    },
  },

  setup(props, context: SetupContext) {
    const body: Ref<HTMLElement | null> = ref(null);
    const visible = ref(false);

    const handleClick = () => {
      if (body.value) {
        expand(body.value);
        visible.value = true;
      }
    };

    return { body, visible, handleClick };
  },
});
