











































import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    alternativeLabel: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const checked = ref(false);
    const index = computed(() => (checked.value ? 1 : 0));
    const computedLabel = computed(() =>
      checked.value ? props.alternativeLabel : props.label,
    );

    return { checked, index, computedLabel };
  },
});
