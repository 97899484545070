import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import VueCompositionApi, { provide } from '@vue/composition-api';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import 'wicg-inert';
import Vue from 'vue';
import ImageSwiperComponent from './components/ImageSwiperComponent.vue';
import NavigationComponent from './components/NavigationComponent.vue';
import IndexComponent from './components/IndexComponent.vue';
import SummaryComponent from './components/SummaryComponent.vue';
import SwitcherComponent from './components/SwitcherComponent.vue';
import AudioComponent from './components/AudioComponent.vue';
import toggle from './directives/toggle';

// App main
const main = async () => {
  // Async load the vue module
  const { useBreakpointTailwindCSS } = await import(
    /* webpackMode: "lazy", webpackChunkName: "vue-composable" */ 'vue-composable'
  );
  const { default: tailwindConfig } = await import(
    /* webpackMode: "lazy", webpackChunkName: "config" */ '../../tailwind.config.js' as any
  );
  const { default: fragmentTypes } = await import(
    /* webpackMode: "lazy", webpackChunkName: "fragment-types" */ './queries/fragmentTypes.json'
  );

  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://b8e8c79c40164e9080f5f019866d5f3d@sentry.urbantrout.io/4',
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      Vue,
      integrations: [new BrowserTracing(), new Sentry.Replay()],
      tracesSampleRate: 0.2,
    });
  }

  Vue.config.productionTip = false;

  Vue.use(VueCompositionApi);

  Vue.directive('toggle', toggle);

  const httpLink = createHttpLink({
    uri: '/api',
  });

  const introspectionQueryResultData: any = fragmentTypes;
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });
  const cache = new InMemoryCache({ fragmentMatcher });

  const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
  });

  // Create our vue instance
  const vm = new Vue({
    el: '#page-container',
    components: {
      NavigationComponent,
      ImageSwiperComponent,
      IndexComponent,
      SummaryComponent,
      SwitcherComponent,
      AudioComponent,
    },

    setup(): any {
      provide(DefaultApolloClient, apolloClient);

      return {
        ...useBreakpointTailwindCSS(tailwindConfig),
      };
    },
  });

  return vm;
};

const closeVideoDialog = (elem: HTMLDialogElement) => {
  const animation = elem.animate(
    [
      { transform: 'translate3d(0, 0, 0)' },
      { transform: 'translate3d(-100%, 0, 0)' },
    ],
    {
      duration: 300,
      easing: 'ease-in',
    },
  );
  animation.addEventListener('finish', () => elem.close());
  sessionStorage.setItem('hideVideo', '1');
};

// Execute async function
main().then(() => {
  const intro = document.getElementById('intro') as HTMLDialogElement | null;
  if (intro && !sessionStorage.getItem('hideVideo')) {
    intro.removeAttribute('hidden');
    intro.showModal();
    Array.from(intro.querySelectorAll('video')).forEach(
      (video: HTMLVideoElement) => {
        // Only watch visible videos
        if (getComputedStyle(video).display === 'none') {
          return;
        }

        video.addEventListener('click', () => {
          closeVideoDialog(intro);
        });
        video.addEventListener('ended', () => {
          closeVideoDialog(intro);
        });
      },
    );
  }
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
