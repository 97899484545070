function expand(target: HTMLElement) {
  const { height: initialHeight } = getComputedStyle(target);

  /* eslint-disable no-param-reassign */
  target.setAttribute('data-initial-height', initialHeight);
  target.style.visibility = 'hidden';
  target.style.maxHeight = 'none';
  /* eslint-enable */
  const { height } = getComputedStyle(target);
  /* eslint-disable no-param-reassign */
  target.style.visibility = 'visible';
  target.style.maxHeight = initialHeight;
  /* eslint-enable */

  // force dom re-render,
  // unless flip may happen
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  getComputedStyle(target).height;

  requestAnimationFrame(() => {
    /* eslint-disable-next-line no-param-reassign */
    target.style.maxHeight = height;
    target.removeAttribute('inert');
  });

  if (parseInt(initialHeight, 10) === 0) {
    setTimeout(() => {
      target.style.removeProperty('max-height');
    }, 200);
  }
}

export { expand };

function collapse(target: HTMLElement) {
  const { height } = getComputedStyle(target);
  // eslint-disable-next-line no-param-reassign
  target.style.maxHeight = height;
  // force dom re-render,
  // unless flip may happen
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  getComputedStyle(target).height;

  requestAnimationFrame(() => {
    // eslint-disable-next-line no-param-reassign
    target.style.maxHeight = target.getAttribute('data-initial-height') || '';
    target.setAttribute('inert', 'inert');
  });
}

export { collapse };

const shuffle = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * i);
    const temp = array[i];
    // eslint-disable-next-line no-param-reassign
    array[i] = array[j];
    // eslint-disable-next-line no-param-reassign
    array[j] = temp;
  }
  return array;
};

export { shuffle };
