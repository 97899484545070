





















import {
  Ref,
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';

export default defineComponent({
  setup() {
    const root: Ref<HTMLElement | null> = ref(null);
    const el: Ref<HTMLElement | null> = ref(null);
    const paginationStyle = ref({});
    const currentIndex = ref(0);
    const total = ref(0);
    let scrollTimeout: any;

    onMounted(() => {
      if (el.value) {
        total.value = el.value.childElementCount;
      }
    });

    const paginationText = computed(() => {
      return `${currentIndex.value + 1} / ${total.value}`;
    });

    const handleMousemove = (event: any) => {
      if (root.value) {
        const { top, left } = root.value.getBoundingClientRect();

        paginationStyle.value = {
          transform: `translate3d(calc(${event.clientX -
            left}px - 50%), calc(${event.clientY - top}px - 50%), 0)`,
        };
      }
    };

    const slideWidth = (): number => {
      if (el.value) {
        return el.value.children[0].scrollWidth;
      }
      return 0;
    };

    const slideNext = (): void => {
      if (el.value) {
        if (currentIndex.value < total.value - 1) {
          currentIndex.value += 1;
          el.value.scrollBy({
            left: slideWidth(),
            behavior: 'smooth',
          });
        }
      }
    };

    const slidePrev = (): void => {
      if (el.value) {
        if (currentIndex.value > 0) {
          currentIndex.value -= 1;
          el.value.scrollBy({
            left: -slideWidth(),
            behavior: 'smooth',
          });
        }
      }
    };

    const handleClick = (event: any) => {
      if (root.value) {
        const { left } = root.value.getBoundingClientRect();
        const width = root.value.offsetWidth;

        if (event.clientX - left < width / 2) {
          slidePrev();
        } else {
          slideNext();
        }
      }
    };

    const handleScroll = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        if (el.value) {
          currentIndex.value = Math.floor(el.value.scrollLeft / slideWidth());
        }
      }, 100);
    };

    return {
      root,
      el,
      paginationStyle,
      paginationText,
      handleMousemove,
      handleClick,
      handleScroll,
      slidePrev,
      slideNext,
    };
  },
});
